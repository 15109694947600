/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

export default {
    heroContainer: {
        position: `relative`,
        pt: [6, 6],
        background: t => `linear-gradient(
          180deg,
            ${t.colors.beta},
          ${t.colors.betaDark} 
        )`
    },

    heading: {
        position: `relative`,
        pt: [2, 4],
        '::before, ::after': {
            position: `absolute`,
            content: `" "`,
            width: [`full`, `90%`],
            height: `110%`,
            top: [`-10%`, 0],
            right: `50%`,
            transform: `translate(50%, 0)`,
            zIndex: -1
        }
    }
}
