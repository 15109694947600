import React from 'react'
import {graphql} from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import {normalizeBlockContentNodes} from '@blocks-helpers'
import Header from "@solid-ui-blocks/Header/Block01/Block01";
import {Container} from "theme-ui";
import Hero from '@solid-ui-blocks/Hero/Block02'
import styles from "../bpm-training/_styles";
import Divider from "@components/Divider";
import TrainingContent from "@solid-ui-blocks/FeaturesWithPhoto/Block01/Block01";
import ModalWithTabs from "@solid-ui-blocks/Modal/Block01/Block01";
import Pricing from '@solid-ui-blocks/Pricing/Block01'

const BPMTraining = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout {...props}>
            <Seo title='BPM Training'/>
            {/* Modals */}
            <ModalWithTabs content={content['contact']}/>
            {/* Blocks */}
            <Header content={content['header-light']}/>
            <Container variant='full' sx={styles.heroContainer}>
                <Hero content={content['hero']}/>
                <Divider space='5'/>
            </Container>
            <Divider space='4'/>
            <TrainingContent content={content['training-contents']}/>
            <Divider space='5'/>
            <Pricing content={content['pricing']}/>
            <Divider space='5'/>
        </Layout>
    )
}


export const query = graphql`
    query innerpageBPMTraining {
        allBlockContent(
            filter: { page: { in: ["site/bpm-training", "shared"] } }
        ) {
            nodes {
                ...BlockContent
            }
        }
    }
`
export default BPMTraining
